import React, { useEffect } from 'react'
/** @jsx jsx */
import Amplify from "@aws-amplify/core";
import { API, graphqlOperation, Cache } from "aws-amplify"
import { ListAllAssets, ListShotAssetsDeltasIndex, ListShotAssetsDateDeltasIndex } from "../graphql/shotassetQueries"

import { Flex, Box, jsx } from 'theme-ui'
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Spinner from 'react-bootstrap/Spinner'
import VideoCard from '../@geocine/gatsby-theme-organization/components/VideoCard'
import Layout from '../@geocine/gatsby-theme-organization/components/Layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft, faSpinner} from "@fortawesome/free-solid-svg-icons";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
import awsconfig from "../aws-exports";

import 'src/css/theme.scss'
import favicon from '../@geocine/gatsby-theme-organization/assets/favicon.ico'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import { AppsContext } from '../AppsProvider'

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false



// http://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/9519550.jpg
Amplify.configure(awsconfig); // Configure Amplify

const colorWhite50 = "rgba(255,255,255, .5)"
const colorBlack50 = "rgba(0,0,0, .5)"
const colorBlack80 = "rgba(0,0,0, .8)"
const colorWhiteHover = "rgba(255,255,255, .7)"
const colorSubLinkBackground = "rgba(0,0,0, .1)"

const linkStyle = {
  color: 'black',
	float: 'left',
	padding: '8px 16px',
	textDecoration: 'none',
	transition: 'backgroundColor .3s',
	':hover': '',
}

const MusicPage = () => {

  const appsContext = React.useContext(AppsContext)

	const currentCategory = "Music"
  const pageNumbers = [1,2,3,4,5,6,7,8,9,10]

	const pages = [1,2,3,4,5,6,7,8];
  	let activePage = 1
  	

  const setCurrentPage = (num) => {
      appsContext.state.currentPage = num
      appsContext.state.activePage = num
      appsContext.state.start = (appsContext.state.activePage-1)*appsContext.state.limit+1
    }
  const handlePageClick = (e, index) => {
     e.preventDefault();
     setCurrentPage(index);
     activePage =  index 
       appsContext.dispatch({ type: "PAGER", activePage });
  };

  const handleNextPageClick = (e, index) => {
     e.preventDefault();
     if(index <= 15) setCurrentPage(index+1);
     activePage =  index + 1


     appsContext.dispatch({ type: "PAGER", activePage });
     appsContext.dispatch({ type: "PAGETOKENS", nextToken: appsContext.state.nextToken })
  };

  const handlePrevPageClick = (e, index) => {
     e.preventDefault();
     if(index > 1) setCurrentPage(index-1)     
     activePage =  index - 1
     appsContext.dispatch({ type: "PAGER", activePage });
     if(index==0) appsContext.dispatch({ type: "PAGETOKENS", nextToken: null })
     else appsContext.dispatch({ type: "PAGETOKENS", nextToken: appsContext.state.prevToken })
  };



	useEffect(() => {
      try{
              if(appsContext.state.user){
                const  userInfo  = appsContext.state.user
                // console.log('userInfo: ', userInfo)
                const uRoles = userInfo.attributes[`custom:roles`].split(',')
                //user roles checks
                if( uRoles.some(r => ['videoAdmin','superAdmin'].indexOf(r) >= 0) ) appsContext.dispatch({ type: "ISADMIN", isAdmin:true })  // checks for one or more permissions to allow access to specific components
              }
              // const favs = Cache.getItem('favorites')
              // console.log('appsContext.state.currentCategory:', appsContext.state.currentCategory)
              // console.log('appsContext.state.categoryFilter:', appsContext.state.categoryFilter)
              // console.log('appsContext.state.categoryMetaField:', appsContext.state.categoryMetaField)
              getCachedAssetData()
              // console.log('isAdmin:', isAdmin)
              if( appsContext.state.isAdmin ){
                getAssetSearchData()
              }
              // getData();
              
              // const subscription = API.graphql(graphqlOperation(onCreateAlbum)).subscribe({
               //    next: eventData => {
               //      const album = eventData.value.data.onCreateAlbum;
               //      dispatch({ type: "SUBSCRIPTION", album });
               //    }
               //  });
                return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  appsContext.dispatch({type:"CLEARALBUMS"})
                };
          } catch(error) {
            // console.log('useEffect error:',error)
          }
			}, [appsContext.state.currentPage]);


	async function getAssetSearchData() {
		
		// console.log('getting all assets...')
	    try{
	    	
      const albumData = await API.graphql(graphqlOperation(ListAllAssets,{coId:"6B3E1A00BDAB94E4DA166A30A759D44C",  searchStr:appsContext.state.categoryFilter, searchType:appsContext.state.categoryMetaField, start: appsContext.state.start, limit: appsContext.state.limit}));
			
		    // console.log('albumData:', albumData)
		    appsContext.dispatch({ type: "QUERY", albums: albumData.data.listAllAssetsFTSearch });

        albumData.data.listAllAssetsFTSearch.forEach( async (assetO) => {
            // addAssetDelta(assetO)
            updateAssetDelta(assetO)
          })
	    }catch(err){
	    	
	    	//add an image placehold for assets with no image
	    	// appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
	    	// console.log('error: ', err)
	    }
	    

	}

  async function getCachedAssetData() {    
    // console.log('getting cached assets...')
      try{
        // console.log('currentCategory:', currentCategory)
      // const albumDataCached = await API.graphql(graphqlOperation(ListShotwireAssetsDeltas,{filter: {category:{contains: "red carpet"}}, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken}));
        // console.log('appsContext.state.cacheLimit:', appsContext.state.cacheLimit)
        const albumDataCached = await API.graphql(graphqlOperation(ListShotAssetsDateDeltasIndex,{category:currentCategory,filter: null, limit: appsContext.state.cacheLimit, nextToken: appsContext.state.nextToken, scannedCount: appsContext.state.scannedCount}));

        // console.log('albumDataCached:', albumDataCached)
        appsContext.dispatch({ type: "QUERYCACHE", albumConnection: albumDataCached.data.listShotAssetsDateDeltasIndex });
        
      }catch(err){        
        //add an image placehold for assets with no image
        // appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
        // console.log('error: ', err)
      }      
  }

  const createAssetDelta = `mutation createAssetDelta($input: CreateShotwireAssetsDeltaInput!) {
      createShotwireAssetsDelta(input: $input) {
        id
        assetId
        name

      }
    }
    `;

  async function addAssetDelta(assetObj) {
    try{
      //*old* decided to use only one category to tag an asset in order to utilize db index
      // // get current category and add this catgory if not already there.
      // // split the category by comma and create array then push item into array but not dupe
      // // console.log('asset cat:',assetObj.category)
      // const tmp = (assetObj.category != null) ? assetObj.category.split(',') : []
      // if(!tmp.includes('red carpet')) tmp.push('red carpet')
      // // console.log('tmp:', tmp)
      // const cat = tmp.join(',')
      // console.log('cat:', cat)
      const asset = {...assetObj, archived: 1, category: currentCategory}
       
      Object.keys(asset).forEach((key) => (asset[key] == null||asset[key] == '') && delete asset[key]);
      // console.log('asset->', asset)
      await API.graphql(graphqlOperation(createAssetDelta, { input: asset }));
    } catch(err) {
      // console.log('delta err:',err)
    }
    
  }

  const assetDeltaUpdate = `mutation updateAssetDelta($input: UpdateShotAssetsDeltaInput!) {
      updateShotAssetsDelta(input: $input) {
        id
        category
        assetId
        name
      }
    }
    `;

  async function updateAssetDelta(assetObj) {
    try{
      // console.log('date:', assetObj.setDate)
      const formattedDate = [assetObj.setDate.slice(0, 4), assetObj.setDate.slice(4,6), assetObj.setDate.slice(6,8)].join('-')
      // console.log('formattedDate:', formattedDate)
      const eventDate = new Date(formattedDate).getTime()
      // console.log('eventDate:',eventDate)
      const asset = {id:assetObj.id, category: currentCategory, timestamp: eventDate }      
      Object.keys(asset).forEach((key) => (asset[key] == null||asset[key] == '') && delete asset[key]);
      // console.log('asset->', asset)
      await API.graphql(graphqlOperation(assetDeltaUpdate, { input: asset }));
    } catch(err) {
      // console.log('delta err:',err)
    }    
  }

  return(
  	
<Layout>

 <Container>
 <Row>
 <div sx={{
  paddingLeft: '10px',
  paddingTop: '20px'
 }}>
 <h2>Music</h2>
 </div>
 </Row>
 <Row>
 <div sx={{
  padding: '10px'
 }}>
<ButtonToolbar aria-label="Pagination Tool Bar">
  <ButtonGroup size="sm" className="mr-2" aria-label="First group">
  
  <Button variant="secondary" onClick={e => handlePrevPageClick(e, appsContext.state.activePage)}>
    <FontAwesomeIcon
          icon={faAngleLeft}
          size="sm"
          sx={{
            // marginRight: '0.5rem'
          }}
        />
     </Button>
		{pages.map(page => (
        <Button
          key={page}
          variant="secondary"
          active={page === appsContext.state.activePage}
          onClick={e => handlePageClick(e, page)}
          // disabled={appsContext.state.currentPage <= 0}
         >
          {page}
        </Button>
      	))
		}
    <Button variant="secondary" onClick={e => handleNextPageClick(e, appsContext.state.activePage)}>
    <FontAwesomeIcon
          icon={faAngleRight}
          size="sm"
          sx={{
            // marginRight: '0.5rem'
          }}
        />
     </Button>     

  </ButtonGroup>
</ButtonToolbar>
</div>
 </Row>

 	<Row>
 	{ appsContext.state.isLoading ? (
          <div sx={{
  paddingLeft: '10px',
 }} >
         <Spinner animation="border" variant="secondary" />
     {   
      // <FontAwesomeIcon
      //       icon={faSpinner}
      //       size="sm"
      //       sx={{
      //         // marginRight: '0.5rem'
      //       }}
      //       spin
      //     />
        }
          </div>
        ) : (
          <div sx={{
  paddingLeft: '10px',
 }}></div>
        )
    }
 	</Row>
 	  <Row>
        
        { 

          appsContext.state.albumsCached.map((asset, key) => (

                <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
                  <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={
                    (asset.thumbNailUrl!=null)?asset.thumbNailUrl:(
                                        (asset.assetId)?
                                        `https://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/${asset.assetId}.jpg`
                                        :`https://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/undefined.jpg`)
                  }>
            [ { (asset.archived ? asset.video_length:'pending') } ]{'   '}{asset.city}<br />
            { (asset.headline) ? asset.headline.substr(0,100):(asset.archived ? 'missing headline':asset.name) } 
                  </VideoCard>
                </Box>
              )
          )
        }

        </Row>
        <Row sx={{borderTop: '1px solid rgba(0,0,0, .5)'}} >
     { 
                  // !appsContext.state.albums.length ? <div sx={{ padding:'10px' }}>**</div> : <div sx={{ padding:'10px' }}>*</div>
                }
        {	

        	appsContext.state.albums.map((asset, key) => (

		            <Box sx={{ width: ['100%', '50%', '33%'], px: 2, py: 2 }} key={key}>
		              <VideoCard assetObj={asset} assetId={asset.assetId} name={ asset.suppCat } href="#!" image={`https://d1w2buicv2uw3m.cloudfront.net/V2/6B3E1A00BDAB94E4DA166A30A759D44C/assets/${asset.assetId}.jpg`}>
						[ {asset.video_length} ]{'   '}{asset.city}<br />
						{ asset.headline.substr(0,100) } 
		              </VideoCard>
		            </Box>
		          )
        	)
        }

        </Row>
        <Row>
        <div sx={{
  padding: '10px'
 }}>
<ButtonToolbar aria-label="Pagination Tool Bar">
  <ButtonGroup size="sm" className="mr-2" aria-label="First group">
  
  <Button variant="secondary" onClick={e => handlePrevPageClick(e, appsContext.state.activePage)}>
    <FontAwesomeIcon
          icon={faAngleLeft}
          size="sm"
          sx={{
            // marginRight: '0.5rem'
          }}
        />
     </Button>
		{pages.map(page => (
        <Button
          key={page}
          variant="secondary"
          active={page === appsContext.state.activePage}
          onClick={e => handlePageClick(e, page)}
          // disabled={appsContext.state.currentPage <= 0}
         >
          {page}
        </Button>
      	))
		}
    <Button variant="secondary" onClick={e => handleNextPageClick(e, appsContext.state.activePage)}>
    <FontAwesomeIcon
          icon={faAngleRight}
          size="sm"
          sx={{
            // marginRight: '0.5rem'
          }}
        />
     </Button>     

  </ButtonGroup>
</ButtonToolbar>
</div>
        </Row>
      </Container>
 </Layout>
 )
}


// <Layout>
//     <SEO title="Celebrity Candids" />
//     <h1>Hi from the second page</h1>
//     <p>Celebrity Candids</p>
//     <Link to="/">Go back to the homepage</Link>
//   </Layout>

export default MusicPage